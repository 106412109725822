/* eslint-disable @typescript-eslint/ban-ts-comment */
import type { FetchResult } from "@apollo/client";
import { ApolloLink, Observable } from "@apollo/client";
import type { DocumentNode, OperationDefinitionNode } from "graphql";
import { Kind } from "graphql";

const QUERIES_DONE_LOADING_EVENT = "queriesDoneLoading";
let queriesLoading = 0;

const getOperationType = (query: DocumentNode) => {
  const definition = query.definitions.find(
    (obj: any) => "kind" in obj && obj.kind === Kind.OPERATION_DEFINITION
  ) as OperationDefinitionNode;

  return definition?.operation;
};

const isQuery = (query: DocumentNode) => {
  return getOperationType(query) === "query";
};

const getQueryLoadingLink = () => {
  return new ApolloLink((operation, forward): Observable<FetchResult> => {
    if (!isQuery(operation.query)) return forward(operation);

    queriesLoading++;

    const opt = forward(operation);

    return new Observable<FetchResult>(observer => {
      const sub = opt.subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),

        complete: () => {
          queriesLoading--;

          if (queriesLoading === 0) {
            document.dispatchEvent(new CustomEvent(QUERIES_DONE_LOADING_EVENT));
          }
          observer.complete();
        },
      });

      return () => sub.unsubscribe();
    });
  });
};

export { getQueryLoadingLink, QUERIES_DONE_LOADING_EVENT };
