import type { Event, EventHint } from "@sentry/types";

const resizeObserverLoop = (event: Event, hint: EventHint): Event | null => {
  const resizeObserverLoopLimit = "ResizeObserver loop limit exceeded";
  const error = hint.originalException as Error | string;

  const isResizeObserverLoopString =
    typeof error === "string" && error.includes(resizeObserverLoopLimit);
  const isResizeObserverLoopError =
    typeof error !== "string" && error?.message?.includes(resizeObserverLoopLimit);

  if (isResizeObserverLoopString || isResizeObserverLoopError) {
    return null;
  }

  return event;
};

export { resizeObserverLoop };
