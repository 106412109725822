import type { Event, EventHint } from "@sentry/types";

const webkitMessageHandlers = (event: Event, hint: EventHint): Event | null => {
  const messageHandlersErrorString = "window.webkit.messageHandlers";
  const error = hint.originalException as Error | string;

  const isMessageHandlersString =
    typeof error === "string" && error.includes(messageHandlersErrorString);
  const isMessageHandlersError =
    typeof error !== "string" && error?.message?.includes(messageHandlersErrorString);

  if (isMessageHandlersString || isMessageHandlersError) {
    return null;
  }

  return event;
};

export { webkitMessageHandlers };
