const SvgEmersonLogo = props => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 432 165.5"
    style={{
      enableBackground: "new 0 0 432 165.5",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style type="text/css">
      {"\n\t.st0{fill:#88B04B;}\n\t.st1{fill:#36485C;}\n\t.st2{fill:#2E3A46;}\n"}
    </style>
    <title id="emerson-title">{"Emerson Logo"}</title>
    <desc id="emerson-desc">{"Emerson Logo"}</desc>
    <g>
      <g>
        <g>
          <path
            className="st0"
            d="M59.4,48.3C69.9,37.1,74.1,20,75.9,18.2c1.6-1.7,0.1,30-24.6,43.9c-13.2,7.4-28,6.8-27.6,4.2 C27.5,35.5,56.4,26.4,58.4,28c0.4,0.3-8.6,3.5-17,12.8c-8.1,9-10.5,18.2-10.8,21.9C30.6,63.6,47.1,61.5,59.4,48.3z"
          />
          <path
            className="st0"
            d="M67.4,14.2c-6.7,0.2-23.5,1.9-34.4,9.8c-8.4,6-13.9,15-15.3,24.5c-0.8,5.6,0.1,10.8,2.2,13.5 c0.4,0.5,0.5,0.5,0.6,0.5c0.4,0,0.1-2.6,0.6-5.4c2.9-20,10.6-34.8,49.4-40.6c0.7-0.1,6.7-0.8,7.3-0.9c0.1,0,0.1-0.1,0-0.3 C77.2,15,73.4,14,67.4,14.2z"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M105.6,66c-12,0-19.4-8.4-19.4-22c0-13.5,7.4-21.9,19.4-21.9c10.1,0,16.1,4.9,16.1,13.2 c0,6.3-3.9,10-12,11.3l-16.3,2.7c1.2,6.7,5.7,10.6,12.3,10.6c3,0,5.6-0.4,7.9-1.1c0,0,2.5-0.7,2.7-0.8c0.9-0.2,1.3,0.3,1.5,0.9 c0,0,0.5,1.6,0.8,2.7c0.3,1.1,0.5,1.7-0.5,2.1c-0.4,0.2-3,1-3,1C112.2,65.6,109.2,66,105.6,66z M105.4,28.2 c-7.9,0-12.2,5.3-12.6,15.4l15.7-2.7c4.4-0.7,6.5-2.6,6.5-5.6C115,30.8,111.5,28.2,105.4,28.2z"
          />
          <path
            className="st1"
            d="M188,65.1c-0.8,0-1.3-0.5-1.3-1.3V39.7c0-7.3-3.1-11.5-8.6-11.5c-10.8,0-12.4,10.5-12.4,16.8v18.9 c0,0.8-0.5,1.3-1.3,1.3h-4.1c-0.8,0-1.3-0.5-1.3-1.3V39.6c0-7.1-3.2-11.4-8.7-11.4c-10.8,0-12.4,10.5-12.4,16.8v18.9 c0,0.8-0.5,1.3-1.3,1.3h-4.1c-0.8,0-1.3-0.5-1.3-1.3V24.1c0-0.8,0.5-1.3,1.3-1.3h2.7c0.7,0,1.2,0.4,1.3,1.2l0.3,5.1 c4.1-5.1,8.1-7.2,13.9-7.2c7.5,0,10.9,5,12.2,8c0.1,0.1,0.3,0.4,0.5,0.4c0.2,0,0.4-0.1,0.6-0.4c3.5-5.5,8-8,14.2-8 c9,0,14.6,6.6,14.6,17.2v24.7c0,0.8-0.5,1.3-1.3,1.3H188z"
          />
          <path
            className="st1"
            d="M250.7,65.1c-0.8,0-1.3-0.5-1.3-1.3V24.1c0-0.8,0.5-1.3,1.3-1.3h2.7c0.7,0,1.2,0.4,1.3,1.2l0.6,6.5 c3.8-5.7,8.2-8.3,14.7-8.5c0.8,0,1.3,0.5,1.3,1.3v3.6c0,0.8-0.5,1.3-1.3,1.3c-9.1,0.4-14,8.1-14,22.3v13.4c0,0.8-0.5,1.3-1.3,1.3 H250.7z"
          />
          <path
            className="st1"
            d="M293.9,66c-5.1,0-9.5-0.7-13.2-2c0,0-1.7-0.5-2.5-0.8c-0.6-0.2-0.9-0.7-0.7-1.3c0.1-0.5,1.3-3.7,1.3-3.7 c0.3-0.7,0.6-0.9,1.3-0.8l2.5,0.8c3.1,1.1,7.1,1.7,11.3,1.7c6,0,9.6-2.4,9.6-6.3c0-2.9-1.6-4.7-5.4-5.8L288,44.9 c-6.6-1.7-9.7-5.2-9.7-11.2c0-7.3,5.7-11.7,15.2-11.7c3.7,0,7.5,0.5,10.2,1.3c0,0,2.9,0.8,3.6,1.1c0.8,0.3,0.8,0.8,0.6,1.6 c-0.2,0.7-0.8,2.8-0.9,3.3c-0.2,0.7-0.7,1.1-1.5,0.8c-0.4-0.1-3.4-0.9-3.4-0.9c-2.3-0.7-5.5-1.1-8.5-1.1c-5.4,0-8.7,2.1-8.7,5.5 c0,2.9,1.5,4.5,5.2,5.6l10,2.8c6.7,1.7,10,5.5,10,11.4C310,61.3,304,66,293.9,66z"
          />
          <path
            className="st1"
            d="M336.9,66c-11.9,0-19.3-8.4-19.3-22c0-13.5,7.4-21.9,19.3-21.9c11.9,0,19.3,8.4,19.3,21.9 C356.1,57.6,348.8,66,336.9,66z M336.9,28.2c-8,0-12.7,5.9-12.7,15.8c0,9.9,4.7,15.9,12.7,15.9c8,0,12.8-5.9,12.8-15.9 C349.7,34.1,344.9,28.2,336.9,28.2z"
          />
          <path
            className="st1"
            d="M396.8,65.1c-0.8,0-1.3-0.5-1.3-1.3V39.7c0-7.2-3.6-11.5-9.7-11.5c-8.3,0-13.1,6.1-13.1,16.8v18.9 c0,0.8-0.5,1.3-1.3,1.3h-4.1c-0.8,0-1.3-0.5-1.3-1.3V24.1c0-0.8,0.5-1.3,1.3-1.3h2.7c0.8,0,1.3,0.5,1.3,1.3l0.3,5.2 c3.8-4.9,8.6-7.3,14.6-7.3c9.7,0,15.7,6.6,15.7,17.2v24.7c0,0.8-0.5,1.3-1.3,1.3H396.8z"
          />
          <path
            className="st1"
            d="M222.3,66c-12,0-19.4-8.4-19.4-22c0-13.5,7.4-21.9,19.4-21.9c10.1,0,16.1,4.9,16.1,13.2 c0,6.3-3.9,10-12,11.3l-16.3,2.7c1.2,6.7,5.7,10.6,12.3,10.6c3,0,5.6-0.4,7.9-1.1c0,0,2.5-0.7,2.7-0.8c0.9-0.2,1.3,0.3,1.5,0.9 c0,0,0.5,1.6,0.8,2.7c0.3,1.1,0.5,1.7-0.5,2.1c-0.4,0.2-3,1-3,1C228.9,65.6,225.9,66,222.3,66z M222.2,28.2 c-7.9,0-12.2,5.3-12.6,15.4l15.7-2.7c4.4-0.7,6.5-2.6,6.5-5.6C231.8,30.8,228.3,28.2,222.2,28.2z"
          />
        </g>
        <g>
          <path
            className="st1"
            d="M167.8,103.8c-1.6,0.4-3.2,0.6-4.6,0.6c-6.9,0-10.7-4.5-10.7-11.5c0-7.1,3.9-11.5,10.5-11.5 c5.5,0,8.8,2.7,8.8,7.2c0,3.5-2.1,5.5-6.7,6.2l-7.1,1.1c0.5,2.4,2.3,3.9,5.2,3.9c1.3,0,2.6-0.2,3.6-0.5l2-0.5 c0.3-0.1,0.5,0,0.6,0.4l0.9,3.4c0.1,0.3,0,0.5-0.4,0.6L167.8,103.8z M164.3,90.9c1.6-0.3,2.5-1,2.5-2.2c0-1.8-1.4-2.8-3.9-2.8 c-3,0-5,1.9-5.4,6L164.3,90.9z"
          />
          <path
            className="st1"
            d="M190.7,103.8c-1.4,0.4-2.8,0.6-4.2,0.6c-7,0-10.8-4.5-10.8-11.6c0-7,3.8-11.5,10.9-11.5 c1.2,0,2.4,0.2,3.9,0.5l2,0.5c0.3,0.1,0.5,0.3,0.4,0.6l-1,3.4c-0.1,0.3-0.3,0.4-0.6,0.3l-1.5-0.4c-1.2-0.3-2.2-0.5-3-0.5 c-4,0-5.9,2.6-5.9,6.9c0,4.4,2,7,5.8,7c1,0,2.2-0.2,3.4-0.5l1.5-0.5c0.3-0.1,0.5,0,0.6,0.3l1,3.4c0.1,0.3,0,0.5-0.4,0.6 L190.7,103.8z"
          />
          <path
            className="st1"
            d="M217.6,92.8c0,7.1-4,11.6-10.5,11.6c-6.5,0-10.5-4.5-10.5-11.6c0-7,4-11.5,10.5-11.5 C213.6,81.4,217.6,85.8,217.6,92.8z M201.6,92.8c0,4.4,2,7,5.5,7c3.4,0,5.5-2.6,5.5-7c0-4.3-2.1-6.9-5.5-6.9 C203.7,85.9,201.6,88.5,201.6,92.8z"
          />
          <path
            className="st1"
            d="M227.6,98.6c0,0.8,0.3,1.2,0.9,1.2c0.2,0,0.2,0,0.3,0c0.3,0,0.5-0.1,0.6,0.3l1.4,3.2c0.1,0.3,0,0.5-0.2,0.7 c-0.6,0.3-1.4,0.4-2.1,0.4c-3.7,0-6-2.1-6-5.8V71.8c0-0.3,0.2-0.5,0.5-0.5h4c0.3,0,0.5,0.2,0.5,0.5V98.6z"
          />
          <path
            className="st1"
            d="M254.7,92.8c0,7.1-4,11.6-10.5,11.6c-6.5,0-10.5-4.5-10.5-11.6c0-7,4-11.5,10.5-11.5 C250.7,81.4,254.7,85.8,254.7,92.8z M238.7,92.8c0,4.4,2,7,5.5,7c3.4,0,5.5-2.6,5.5-7c0-4.3-2.1-6.9-5.5-6.9 C240.8,85.9,238.7,88.5,238.7,92.8z"
          />
          <path
            className="st1"
            d="M264.2,109.1c1,0.3,2.6,0.5,3.9,0.5c3.1,0,6.2-1.3,6.8-4.9c0.2-0.9,0.2-1.9,0.2-2.8c0-0.3-0.2-0.5-0.6-0.1 c-1.5,1.6-3.7,2.6-6.3,2.6c-6,0-9.6-4.5-9.6-11.4c0-7.3,4-11.6,10.6-11.6c1.9,0,3.8,0.3,5.6,1l4,1.7c0.3,0.1,0.4,0.3,0.4,0.5v19 c0,6.7-4,10.4-10.9,10.4c-1.7,0-3.7-0.2-5-0.6l-2.2-0.6c-0.3-0.1-0.5-0.3-0.4-0.6l1-3.3c0.1-0.4,0.4-0.4,0.8-0.3L264.2,109.1z  M272.8,86.7c-1-0.5-2.4-0.8-3.7-0.8c-3.5,0-5.5,2.5-5.5,6.9c0,4.1,1.9,7,5.2,7c3.5,0,5.4-2.5,5.4-6.9v-5.6L272.8,86.7z"
          />
          <path
            className="st1"
            d="M291.5,103.4c0,0.3-0.2,0.5-0.5,0.5h-4c-0.3,0-0.5-0.2-0.5-0.5v-17h-2.2c-0.3,0-0.5-0.2-0.5-0.5v-3.5 c0-0.3,0.2-0.5,0.5-0.5h6.8c0.3,0,0.5,0.2,0.5,0.5V103.4z M291.5,74c0,2-1.1,3.1-3,3.1c-1.9,0-3-1.1-3-3.1c0-1.9,1.1-3.1,3-3.1 C290.4,70.9,291.5,72.1,291.5,74z"
          />
          <path
            className="st1"
            d="M311.6,103.8c-1.4,0.4-2.8,0.6-4.2,0.6c-7,0-10.8-4.5-10.8-11.6c0-7,3.8-11.5,10.9-11.5 c1.2,0,2.5,0.1,3.9,0.5l2,0.5c0.3,0.1,0.5,0.3,0.4,0.6l-1,3.4c-0.1,0.3-0.3,0.4-0.6,0.3l-1.5-0.4c-1.2-0.3-2.2-0.5-3-0.5 c-4,0-5.9,2.6-5.9,6.9c0,4.4,2,7,5.8,7c1,0,2.2-0.2,3.4-0.5l1.5-0.5c0.3-0.1,0.5,0,0.6,0.3l1,3.4c0.1,0.3,0,0.5-0.4,0.6 L311.6,103.8z"
          />
          <path
            className="st1"
            d="M324.2,94.1c-3.8-1-5.5-2.9-5.5-6.2c0-4.1,3-6.5,8.3-6.5c2,0,4.1,0.3,5.6,0.7l2,0.5c0.3,0.1,0.5,0.3,0.4,0.6 l-1,3.4c-0.1,0.3-0.3,0.5-0.6,0.4l-2-0.5c-1.2-0.4-3-0.5-4.4-0.5c-2.1,0-3.3,0.8-3.3,2c0,1,0.6,1.7,2,2.1l4.5,1.2 c4,1,5.7,3.1,5.7,6.3c0,4.3-3.2,6.9-8.5,6.9c-2.4,0-4.7-0.3-7-1l-1.8-0.5c-0.3-0.1-0.5-0.3-0.4-0.6l1-3.4 c0.1-0.3,0.3-0.4,0.6-0.3l1.6,0.5c1.7,0.5,4.1,0.8,5.8,0.8c2.2,0,3.5-0.9,3.5-2.3c0-1.1-0.6-1.8-2.1-2.2L324.2,94.1z"
          />
        </g>
      </g>
      <g>
        <path
          className="st2"
          d="M415.1,19c0,2.9-1.9,4.8-4.7,4.8c-2.8,0-4.7-1.9-4.7-4.8c0-2.9,1.9-4.8,4.7-4.8 C413.2,14.2,415.1,16.1,415.1,19z M406.5,19c0,2.4,1.5,4,3.9,4c2.3,0,3.9-1.6,3.9-4c0-2.4-1.5-4-3.9-4 C408.1,15.1,406.5,16.7,406.5,19z M410.7,16.6c1,0,1.6,0.6,1.6,1.4c0,0.7-0.4,1.1-0.9,1.2c-0.1,0-0.1,0.1,0,0.1 c0.3,0.2,0.5,0.5,0.7,0.8l0.5,1.1c0.1,0.1,0,0.2-0.1,0.2h-0.6c-0.1,0-0.1,0-0.2-0.1l-0.3-0.8c-0.3-0.6-0.6-0.9-1.3-0.9h-0.4v1.7 c0,0.1-0.1,0.1-0.1,0.1h-0.6c-0.1,0-0.1-0.1-0.1-0.1v-4.6c0-0.1,0.1-0.1,0.1-0.1H410.7z M409.5,18.9h0.8c0.7,0,1.1-0.3,1.1-0.8 c0-0.5-0.3-0.7-0.8-0.7h-1.1V18.9z"
        />
      </g>
    </g>
    <g id="FBns7F.tif" />
    <g>
      <path
        className="st1"
        d="M115,154.7c-1.3,0-2.4-0.3-3.4-0.9c-1-0.6-1.7-1.5-2.3-2.6s-0.8-2.5-0.8-4c0-1.5,0.3-2.9,0.8-4 c0.5-1.1,1.3-2,2.3-2.7c1-0.6,2.1-1,3.4-1c1.3,0,2.5,0.3,3.4,1c0.9,0.7,1.6,1.6,1.9,2.8l-0.3,0.2v-3.7h2.3v14.6h-2.3v-3.8l0.3,0.2 c-0.3,1.2-1,2.2-1.9,2.8C117.4,154.3,116.3,154.7,115,154.7z M115.4,152.7c1.4,0,2.5-0.5,3.3-1.5c0.8-1,1.2-2.4,1.2-4.2 c0-1.8-0.4-3.2-1.2-4.1c-0.8-1-1.9-1.5-3.3-1.5c-1.4,0-2.5,0.5-3.3,1.5c-0.8,1-1.2,2.4-1.2,4.2c0,1.8,0.4,3.2,1.2,4.1 C112.9,152.2,114,152.7,115.4,152.7z"
      />
      <path className="st1" d="M134.1,154.5v-20.6h12.8v2h-10.4v7.2h9.8v2h-9.8v9.5H134.1z" />
      <path
        className="st1"
        d="M154.7,154.7c-1.7,0-3-0.5-3.9-1.5c-0.9-1-1.3-2.4-1.3-4.3v-9h2.3v9c0,1.3,0.3,2.3,0.8,2.9 c0.5,0.6,1.4,0.9,2.5,0.9c1.3,0,2.3-0.4,3.1-1.2c0.8-0.8,1.2-1.9,1.2-3.3v-8.2h2.3v14.6h-2.3v-3.2l0.3-0.2c-0.4,1.2-1,2-2,2.6 C157,154.4,155.9,154.7,154.7,154.7z"
      />
      <path className="st1" d="M166,154.5v-21.3h2.3v21.3H166z" />
      <path className="st1" d="M172.7,154.5v-21.3h2.3v21.3H172.7z" />
      <path
        className="st1"
        d="M184.3,154.7c-2.5,0-4.4-0.6-5.8-1.9l0.8-1.7c0.8,0.6,1.6,1,2.4,1.3c0.8,0.3,1.7,0.4,2.7,0.4 c1.1,0,1.9-0.2,2.5-0.6c0.6-0.4,0.9-0.9,0.9-1.6c0-0.6-0.2-1-0.6-1.4c-0.4-0.3-1-0.6-1.9-0.8l-2.5-0.6c-1.2-0.3-2.2-0.8-2.8-1.5 s-1-1.5-1-2.5c0-0.9,0.2-1.6,0.7-2.2c0.5-0.6,1.1-1.2,1.9-1.5c0.8-0.4,1.8-0.6,3-0.6c1,0,2,0.2,2.9,0.5c0.9,0.3,1.7,0.8,2.3,1.4 l-0.8,1.7c-0.7-0.6-1.4-1-2.1-1.3c-0.7-0.3-1.5-0.4-2.3-0.4c-1,0-1.9,0.2-2.4,0.6c-0.6,0.4-0.9,1-0.9,1.7c0,0.6,0.2,1.1,0.5,1.4 c0.4,0.3,0.9,0.6,1.7,0.8l2.5,0.6c1.4,0.3,2.4,0.8,3,1.4c0.6,0.7,1,1.5,1,2.5c0,1.3-0.5,2.3-1.5,3 C187.4,154.3,186,154.7,184.3,154.7z"
      />
      <path
        className="st1"
        d="M199.6,154.7c-1.4,0-2.7-0.3-3.7-0.9c-1-0.6-1.9-1.5-2.4-2.6c-0.6-1.1-0.9-2.4-0.9-4c0-1.5,0.3-2.8,0.9-4 c0.6-1.1,1.4-2,2.5-2.7c1.1-0.6,2.3-1,3.8-1c1,0,1.9,0.2,2.9,0.5c0.9,0.3,1.7,0.8,2.3,1.4l-0.8,1.7c-0.7-0.6-1.4-1-2.1-1.3 c-0.7-0.3-1.4-0.4-2.1-0.4c-1.5,0-2.6,0.5-3.5,1.5c-0.9,1-1.3,2.4-1.3,4.2c0,1.8,0.4,3.2,1.3,4.1c0.9,1,2,1.5,3.5,1.5 c0.7,0,1.4-0.1,2.1-0.4s1.4-0.7,2.1-1.3l0.8,1.7c-0.6,0.6-1.4,1-2.3,1.4C201.5,154.5,200.6,154.7,199.6,154.7z"
      />
      <path
        className="st1"
        d="M207.5,154.5V144c0-0.7,0-1.4,0-2c0-0.7-0.1-1.4-0.2-2h2.2l0.3,3.6l-0.3-0.1c0.3-1.3,0.9-2.2,1.8-2.9 c0.9-0.6,1.9-1,3-1c0.3,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.6,0.1l-0.1,2.1c-0.4-0.2-1-0.2-1.5-0.2c-1,0-1.8,0.2-2.4,0.7 c-0.6,0.4-1.1,1-1.3,1.7c-0.3,0.7-0.4,1.4-0.4,2.2v8.3H207.5z"
      />
      <path
        className="st1"
        d="M217.6,133.7h2.9v2.7h-2.9V133.7z M217.8,154.5v-14.6h2.3v14.6H217.8z"
      />
      <path
        className="st1"
        d="M224.4,160.7V144c0-0.7,0-1.4,0-2c0-0.7-0.1-1.4-0.2-2h2.2l0.3,3l-0.2,0.4c0.3-1.2,1-2.2,1.9-2.8 c0.9-0.7,2-1,3.4-1c1.3,0,2.4,0.3,3.4,1s1.7,1.5,2.3,2.7c0.6,1.1,0.8,2.5,0.8,4c0,1.5-0.3,2.9-0.8,4s-1.3,2-2.3,2.6 c-1,0.6-2.1,0.9-3.4,0.9c-1.3,0-2.4-0.3-3.4-1s-1.6-1.6-1.9-2.8l0.3-0.3v10.1H224.4z M231.3,152.7c1.4,0,2.5-0.5,3.3-1.5 c0.8-1,1.2-2.3,1.2-4.1c0-1.8-0.4-3.2-1.2-4.2c-0.8-1-1.9-1.5-3.3-1.5c-1.5,0-2.6,0.5-3.3,1.5s-1.2,2.3-1.2,4.1 c0,1.8,0.4,3.2,1.2,4.2S229.8,152.7,231.3,152.7z"
      />
      <path
        className="st1"
        d="M239.5,141.7v-1.9h9.4v1.9H239.5z M248.8,152.4v2c-0.3,0.1-0.7,0.2-1,0.2c-0.3,0-0.7,0.1-1.1,0.1 c-1.4,0-2.4-0.4-3.2-1.2c-0.8-0.8-1.2-1.9-1.2-3.5v-13.9l2.3-0.8v14.4c0,0.8,0.1,1.4,0.3,1.8c0.2,0.4,0.5,0.7,0.9,0.9 s0.8,0.2,1.3,0.2c0.3,0,0.6,0,0.8-0.1C248.2,152.5,248.5,152.5,248.8,152.4z"
      />
      <path
        className="st1"
        d="M264.8,154.7c-1.4,0-2.7-0.3-3.7-0.9c-1-0.6-1.9-1.5-2.4-2.6s-0.9-2.4-0.9-4c0-1.5,0.3-2.8,0.9-4 c0.6-1.1,1.4-2,2.5-2.7s2.3-1,3.8-1c1,0,1.9,0.2,2.9,0.5c0.9,0.3,1.7,0.8,2.3,1.4l-0.8,1.7c-0.7-0.6-1.4-1-2.1-1.3 s-1.4-0.4-2.1-0.4c-1.5,0-2.6,0.5-3.5,1.5c-0.9,1-1.3,2.4-1.3,4.2c0,1.8,0.4,3.2,1.3,4.1c0.9,1,2,1.5,3.5,1.5 c0.7,0,1.4-0.1,2.1-0.4c0.7-0.3,1.4-0.7,2.1-1.3l0.8,1.7c-0.6,0.6-1.4,1-2.3,1.4C266.8,154.5,265.9,154.7,264.8,154.7z"
      />
      <path
        className="st1"
        d="M278.9,154.7c-1.4,0-2.6-0.3-3.6-0.9c-1-0.6-1.8-1.5-2.4-2.6s-0.9-2.5-0.9-4c0-1.5,0.3-2.9,0.9-4 c0.6-1.1,1.4-2,2.4-2.6s2.2-0.9,3.6-0.9s2.6,0.3,3.6,0.9s1.8,1.5,2.4,2.6c0.6,1.1,0.9,2.5,0.9,4c0,1.5-0.3,2.9-0.9,4 c-0.6,1.1-1.4,2-2.4,2.6C281.5,154.4,280.2,154.7,278.9,154.7z M278.9,152.7c1.4,0,2.5-0.5,3.3-1.4s1.2-2.4,1.2-4.2 c0-1.8-0.4-3.2-1.2-4.1s-1.9-1.5-3.3-1.5s-2.5,0.5-3.3,1.5c-0.8,1-1.2,2.3-1.2,4.1c0,1.8,0.4,3.2,1.2,4.2S277.4,152.7,278.9,152.7z "
      />
      <path
        className="st1"
        d="M289.1,154.5V144c0-0.7,0-1.4,0-2c0-0.7-0.1-1.4-0.2-2h2.2l0.3,3l-0.3,0.2c0.3-1.1,1-2,1.8-2.6 c0.9-0.6,1.9-0.9,3.1-0.9c1.4,0,2.5,0.3,3.3,1c0.8,0.7,1.3,1.8,1.5,3.2l-0.7-0.4c0.3-1.2,0.9-2.1,1.9-2.8c0.9-0.7,2.1-1,3.4-1 c1.6,0,2.8,0.5,3.6,1.4c0.8,1,1.2,2.4,1.2,4.3v9.2h-2.3v-9c0-1.4-0.2-2.3-0.7-3c-0.5-0.6-1.2-0.9-2.3-0.9c-1.2,0-2.2,0.4-2.9,1.2 s-1.1,2-1.1,3.4v8.3h-2.3v-9c0-1.4-0.2-2.3-0.7-3c-0.5-0.6-1.3-0.9-2.3-0.9c-1.2,0-2.2,0.4-2.9,1.2c-0.7,0.8-1.1,2-1.1,3.4v8.3 H289.1z"
      />
      <path
        className="st1"
        d="M314.4,160.7V144c0-0.7,0-1.4,0-2c0-0.7-0.1-1.4-0.2-2h2.2l0.3,3l-0.2,0.4c0.3-1.2,1-2.2,1.9-2.8 c0.9-0.7,2-1,3.4-1c1.3,0,2.4,0.3,3.4,1c1,0.6,1.7,1.5,2.3,2.7c0.6,1.1,0.8,2.5,0.8,4c0,1.5-0.3,2.9-0.8,4c-0.6,1.1-1.3,2-2.3,2.6 c-1,0.6-2.1,0.9-3.4,0.9c-1.3,0-2.4-0.3-3.4-1c-0.9-0.7-1.6-1.6-1.9-2.8l0.3-0.3v10.1H314.4z M321.2,152.7c1.4,0,2.5-0.5,3.3-1.5 c0.8-1,1.2-2.3,1.2-4.1c0-1.8-0.4-3.2-1.2-4.2c-0.8-1-1.9-1.5-3.3-1.5c-1.5,0-2.6,0.5-3.3,1.5c-0.8,1-1.2,2.3-1.2,4.1 c0,1.8,0.4,3.2,1.2,4.2C318.7,152.2,319.8,152.7,321.2,152.7z"
      />
      <path
        className="st1"
        d="M337.1,154.7c-1.3,0-2.4-0.3-3.4-0.9c-1-0.6-1.7-1.5-2.3-2.6c-0.5-1.1-0.8-2.5-0.8-4c0-1.5,0.3-2.9,0.8-4 c0.5-1.1,1.3-2,2.3-2.7c1-0.6,2.1-1,3.4-1c1.3,0,2.5,0.3,3.4,1c0.9,0.7,1.6,1.6,1.9,2.8l-0.3,0.2v-3.7h2.3v14.6H342v-3.8l0.3,0.2 c-0.3,1.2-1,2.2-1.9,2.8S338.5,154.7,337.1,154.7z M337.6,152.7c1.4,0,2.5-0.5,3.3-1.5s1.2-2.4,1.2-4.2c0-1.8-0.4-3.2-1.2-4.1 s-1.9-1.5-3.3-1.5c-1.4,0-2.5,0.5-3.3,1.5c-0.8,1-1.2,2.4-1.2,4.2c0,1.8,0.4,3.2,1.2,4.1C335,152.2,336.1,152.7,337.6,152.7z"
      />
      <path
        className="st1"
        d="M348.7,154.5V144c0-0.7,0-1.4,0-2c0-0.7-0.1-1.4-0.2-2h2.2l0.3,3l-0.3,0.2c0.4-1.2,1.1-2.1,2-2.7 s2-0.9,3.3-0.9c3.5,0,5.2,1.9,5.2,5.8v9.2h-2.3v-9c0-1.4-0.3-2.3-0.8-3c-0.5-0.6-1.4-0.9-2.6-0.9c-1.4,0-2.4,0.4-3.2,1.2 c-0.8,0.8-1.2,2-1.2,3.4v8.4H348.7z"
      />
      <path
        className="st1"
        d="M370,155.2l-6.6-15.3h2.5l5.3,12.9L370,155.2z M364.7,161l-0.5-1.8c0.9-0.2,1.6-0.5,2.2-0.7 c0.6-0.3,1.1-0.6,1.5-1.1s0.8-1,1.1-1.8l0.9-2.1l0.5-0.7l5.3-12.9h2.3l-6.8,15.9c-0.5,1.2-1.1,2.1-1.8,2.8s-1.4,1.2-2.2,1.6 C366.6,160.6,365.7,160.9,364.7,161z"
      />
    </g>
  </svg>
);
export default SvgEmersonLogo;
