const SvgFullscriptLogoLeaf = props => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 63 54"
    style={{
      enableBackground: "new 0 0 63 54",
    }}
    xmlSpace="preserve"
    role="img"
    {...props}
  >
    <style type="text/css">{"\n\t.st0{fill:#88B04B;}\n"}</style>
    <title>{"Fullscript leaf logo"}</title>
    <g id="_x30_6" transform="translate(-425.000000, -738.000000)">
      <g id="Page-1-Copy-4" transform="translate(399.000000, 707.000000)">
        <path
          id="Fill-4"
          className="st0"
          d="M82.4,52c0,0,0,0.1,0,0.2c0,0.8-1.2,17.6-21.9,25.2c0,0,0,0,0,0V66.9l0,0L73,53.3 c1.1-1.2,1.1-3.1-0.2-4.3c-1.2-1.1-3.1-1.1-4.3,0.2L60.5,58l0,0V49c0,0,0,0,0-0.1c1-2.3,5.8-11.3,17.6-11.3c0.1,0,0.2,0,0.3,0 C83.1,43.2,82.4,51.9,82.4,52z M54.5,58L54.5,58l-8.1-8.8c-1.1-1.2-3-1.3-4.2-0.2c-1.2,1.1-1.3,3.1-0.2,4.3l12.5,13.6l0,0v10.4 c0,0,0,0,0,0c-20.7-7.6-21.8-24.5-21.9-25.2c0-0.1,0-0.1,0-0.2c0-0.1-0.8-8.8,3.9-14.4c0.1,0,0.2,0,0.4,0 c11.9,0,16.6,9.1,17.6,11.3c0,0,0,0,0,0.1V58z M82,32.4c-0.5-0.5-1.2-0.8-1.9-0.9c-11.3-0.7-18.7,5.1-22.6,10.5 c-3.9-5.4-11.2-11.2-22.6-10.5c-0.7,0-1.4,0.4-1.9,0.9c-7.3,7.3-6.5,18.9-6.4,20c0,0.8,0.5,6.5,4.2,13.1 c3.5,6.3,10.9,14.6,25.6,18.8c0.1,0,0.2,0,0.2,0.1h0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0 c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1c27.3-7.7,29.7-29.7,29.8-31.8C88.4,51.9,89.4,39.9,82,32.4z"
        />
      </g>
    </g>
  </svg>
);
export default SvgFullscriptLogoLeaf;
