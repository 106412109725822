import type { Event, EventHint } from "@sentry/types";

const chunkLoadError = (event: Event, hint: EventHint): Event | null => {
  const chunkLoadErrorString = "ChunkLoadError";
  const error = hint.originalException as Error | string;

  const isChunkLoadingString = typeof error === "string" && error.includes(chunkLoadErrorString);
  const isChunkLoadingError =
    typeof error !== "string" && error?.name?.includes(chunkLoadErrorString);

  if (isChunkLoadingString || isChunkLoadingError) {
    return null;
  }

  return event;
};

export { chunkLoadError };
