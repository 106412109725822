import type { Event, EventHint } from "@sentry/types";

const objectMatchingId = (event: Event, hint: EventHint): Event | null => {
  const objectNotFound = "Object Not Found Matching Id:";
  const error = hint.originalException as Error | string;

  const isObjectNotFoundString = typeof error === "string" && error.includes(objectNotFound);
  const isObjectNotFoundError =
    typeof error !== "string" && error?.message?.includes(objectNotFound);

  if (isObjectNotFoundString || isObjectNotFoundError) {
    return null;
  }

  return event;
};

export { objectMatchingId };
