import type { Event, EventHint } from "@sentry/types";

const crazyEggErrors = (event: Event, hint: EventHint): Event | null => {
  const crazyEggDomain = "https://script.crazyegg.com";
  const error = hint.originalException as Error | string;

  const isCrazyEggString = typeof error === "string" && error.includes(crazyEggDomain);
  const isCrazyEggError = typeof error !== "string" && error?.message?.includes(crazyEggDomain);

  if (isCrazyEggString || isCrazyEggError) {
    return null;
  }

  return event;
};

export { crazyEggErrors };
